export const musicTableauTeho = [
  {
    id: "LTA003L",
    artist: "Teho",
    label: "Labo T.",
    titre: "Shifting (Live at Badaboum)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA003L.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album//7mO8lbV5cVXCztINFfeGlP?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/TehoShiftingLive",
  },
  {
    id: "LTA003D",
    artist: "Teho",
    label: "Labo T.",
    titre: "Shifting (Deluxe Edition)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA003D.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album//1fct2KWsBYi1iNklt2P17c?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/TehoShiftingDeluxeEdition",
  },
  {
    id: "LT0073",
    artist: "Teho & Romain Garcia",
    label: "Labo T.",
    titre: "Day Off (Felix Raphael remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0072.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album//35oT5RHYn3dNoIdUVCHQrt?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/DayOffFelixRaphaelRemix",
  },
  {
    id: "LT0072",
    artist: "Teho",
    label: "Labo T.",
    titre: "Five - 0 (Nuage remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0072.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album//5SfWD0X0EFVDoLXHPJRznn?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Five0NuageRemix",
  },
  {
    id: "LT0071",
    artist: "Teho",
    label: "Labo T.",
    titre: "Origins (Fejkà remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0071.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album//0sISLBRXN3RZv5TfIH6mjI?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/OriginsFejkaRemix",
  },
  {
    id: "LT0070",
    artist: "Teho",
    label: "Labo T.",
    titre: "Continuum (Oxia remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0070.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album//6bG5034cL2LbRtDGFdeBKA?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/ContinuumMoritzHofbauerRemix",
  },
  {
    id: "LT0069",
    artist: "Teho",
    label: "Labo T.",
    titre: "Continuum (Moritz Hofbauer rmx)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0069.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/0CGXqPj2TDdDJxpZU6W8tT?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/ContinuumMoritzHofbauerRemix",
  },
  {
    id: "LT0068",
    artist: "Teho",
    label: "Labo T.",
    titre: "Five-0 (Tonic Walter remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0068.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/1sd0hP881yKdtcUy3ipxP2?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Five0TonicWalterRemix",
  },
  {
    id: "LT0067",
    artist: "Teho",
    label: "Labo T.",
    titre: "Lyra (Lauren Mia remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0067.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/4ZJFNERwiHGbMlwbpSl6NZ?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/LyraLaurenMiaRemix",
  },
  {
    id: "LT0066",
    artist: "Teho",
    label: "Labo T.",
    titre: "Antinomy (Nihils remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0066.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/5yvWI51I7yXhjUsOcXOkfN?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/AntinomyNihilsRemix",
  },
  {
    id: "LT0066",
    artist: "Moritz Hofbauer",
    label: "Fcking Serious",
    titre: "In A Blurry World (Teho remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/Moritzremix.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/55uKvWwpBFVQLY6AtljvPY?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://paradise.ffm.to/inablurryworldremix",
  },
  {
    id: "LTA0035",
    artist: "Teho",
    label: "Labo T.",
    titre: "Shifing (ALBUM)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA003.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/436NZkW2SJLX9MxWHoNCmu?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/TehoShifting",
  },
  {
    id: "LT0065",
    artist: "Teho",
    label: "Labo T.",
    titre: "Rising",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0065.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/404lbl3vOGf4UX8aYauA6D?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/TehoRising",
  },
  {
    id: "LT0064",
    artist: "Teho",
    label: "Labo T.",
    titre: "Continuum",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0064.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/3jvaSyLdngxhG2yNKwNF1E?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Continuum",
  },
  {
    id: "LT0063",
    artist: "Teho",
    label: "Labo T.",
    titre: "Origins",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0063.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/1amcG5DOmiQc2cQeOWzVeD?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Origins",
  },
  {
    id: "LT0062",
    artist: "Teho & Makoto San",
    label: "Labo T.",
    titre: "Hanten",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0062.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/0aHGFImZfPjGHXlbUdijvX?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Teho_MakotoSan_Hanten",
  },
  {
    id: "LT0061",
    artist: "Teho & Romain Garcia",
    label: "Labo T.",
    titre: "Day Off",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0061.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/7qnjYeLG053eq1Dr1oqyMW?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Teho_RomainGarcia_DayOff",
  },
  {
    id: "LT0060",
    artist: "Teho",
    label: "Labo T.",
    titre: "Lyra",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0060.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/10EVZVgK0jaOVuIW1A8GSy?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Lyra",
  },
  {
    id: "EINMUSIKA253E",
    artist: "Teho",
    label: "Einmusika",
    titre: "Breathe",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_Breathe.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/7hpYEfuWF2TNipMeroRxmi?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://music.einmusika.com/breathe",
  },
  {
    id: "LTHS001",
    artist: "Teho & Gregory Terlikowski",
    label: "Labo T.",
    titre: "Backfirewall (OST)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTHS001.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/0QJcQdc8GPPjdgPwDbQ2md?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/BackfirewallOST",
  },
  {
    id: "LTA002D",
    artist: "Teho",
    label: "Labo T.",
    titre: "Infinity (Deluxe Edition)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA002D.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/64Z7e0YTTtoa7dRhpQb6kv?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/InfinityDeluxeEdition",
  },
  {
    id: "LT0051",
    artist: "Teho & Aalson",
    label: "Labo T.",
    titre: "Beyond (Aparde remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0051.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/1BqNZNZ4CkcwpPLeU8rWmB?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/BeyondApardeRemix",
  },
  {
    id: "LT0050",
    artist: "Teho",
    label: "Labo T.",
    titre: "At Any Cost (Romain Garcia remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0050.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/412MxLUxdyeNwI32652nl6?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/AtAnyCost_remix",
  },
  {
    id: "LT0049",
    artist: "Teho",
    label: "Labo T.",
    titre: "Millie (Jonas Saalbach remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0049.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/5DX5EMXoW6ApwP1Sldb1UF?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Millie_Jonas_Saalbach_rmx",
  },
  {
    id: "LT0048",
    artist: "Teho & Joris Delacroix",
    label: "Labo T.",
    titre: "Fury (pølaroit remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0048.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/0KASc800NgdIUXeNWvFXYz?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Fury_polaroit_remix",
  },
  {
    id: "LT0047",
    artist: "Teho",
    label: "Labo T.",
    titre: "Unlocked (Einmusik remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0047.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/6vzHHn8bY3ja0rwkbhZ4NB?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Unlocked_Einmusik_remix",
  },
  {
    id: "LTA002",
    artist: "Teho",
    label: "Labo T.",
    titre: "Infinity",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA002.jpg"}`,
    lecteur: (
<iframe src="https://open.spotify.com/embed/album/0SERodr4s71gsU2o2RcaSK?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>    ),
    lien: "https://fanlink.tv/Teho_Infinity",
  },
  {
    id: "LT0046",
    artist: "Teho",
    label: "Labo T.",
    titre: "Dark Matter",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0046.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/track/4qsfGLGlXsCgzlGgr3NMuT?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Teho_DarkMatter",
  },
  {
    id: "LT0045",
    artist: "Teho & Ceas",
    label: "Labo T.",
    titre: "Stern",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0045.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/7bOb3nmZQPxPNucOsCY2pS?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Teho-Ceas_Stern",
  },
  {
    id: "LT0044",
    artist: "Teho",
    label: "Labo T.",
    titre: "At Any Cost",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0044.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/45kHe1zex3Qg2rfv5Wo6SN?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_AtAnyCost",
  },
  {
    id: "LT0043",
    artist: "Teho & Joris Delacroix",
    label: "Labo T.",
    titre: "Fury",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0043.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3QtWWfAjUAfVaLgKYwzueb?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho-JorisDelacroix_Fury",
  },
  {
    id: "LT0042",
    artist: "Teho & Aalson",
    label: "Labo T.",
    titre: "Beyond",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0042.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3KArNyUMUdAXmnwP72PQSz?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho-Aalson_Beyond",
  },
  {
    id: "LT0041",
    artist: "Teho",
    label: "Labo T.",
    titre: "Millie",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0041.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/56TxBcQTE7VSyhLorRTuvz?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Millie",
  },
  {
    id: "EXT0018",
    artist: "Teho",
    label: "Cercle records",
    titre: "Glacier 3000",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_Glacier_3000.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/track/4YyTSL5nlHICPyX50Gc4aZ?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Glacier3000",
  },
  {
    id: "LT0035",
    artist: "Seth Schwarz & Teho",
    label: "Labo T.",
    titre: "Nasaja",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0035.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6LyoyyrIHlozgyGE6rVoXl?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho-SethSchwarz_Nasaja",
  },
  {
    id: "LTA001D",
    artist: "Teho",
    label: "Labo T.",
    titre: "Polytone (Deluxe Edition)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA001D.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6ccOaSBfxHsqqzpa2ztGMf?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Polytone-DeluxeEdition",
  },
  {
    id: "LT0030",
    artist: "Teho",
    label: "Labo T.",
    titre: "2000 Miles",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0030.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/0FrW1IyTv37LMxbYxpuzvA?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_2000Miles",
  },
  {
    id: "LT0029",
    artist: "Teho",
    label: "Labo T.",
    titre: "GV (Clawz SG remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0029.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/1MDQWoNj72NksgwoVbENTW?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_GV-ClawzSGRemix",
  },
  {
    id: "LT0028",
    artist: "Teho",
    label: "Labo T.",
    titre: "Anything (Joachim Pastor remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0028.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/0f1xxKz6DbqkJlmDDi23BI?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Anything-JoachimPastorRemix",
  },
  {
    id: "LT0027",
    artist: "Teho",
    label: "Labo T.",
    titre: "Anything",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0027.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6m4Ri3QeAEGgqyWQS6B7AX?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Anything-CeasRemix",
  },
  {
    id: "LT0026",
    artist: "Teho",
    label: "Labo T.",
    titre: "Signal (Ron Flatter remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0026.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/20DdDcdajk5VQxfk2kJy5r?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Signal-RonFlatterRemix",
  },
  {
    id: "LT0025",
    artist: "Teho",
    label: "Labo T.",
    titre: "Irani (Joris Delacroix remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0025.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3mSD8QLyj9Nl7jAC82J2c6?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Irani-JorisDelacroixRemix",
  },
  {
    id: "LTA001",
    artist: "Teho",
    label: "Labo T.",
    titre: "Polytone (ALBUM)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA001.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/0LSCZqG1dAwWKeBqDvmiTh?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Polytone",
  },
  {
    id: "LT0024",
    artist: "Teho",
    label: "Labo T.",
    titre: "Anything (feat. Carott)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0024.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/76Gb5YWNrKm8WDxV30TBdG?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Anything",
  },
  {
    id: "LT0023",
    artist: "Teho",
    label: "Labo T.",
    titre: "Yamataï",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0023.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/78R4f1dzU4xie8BOY0XGAC?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Yamatai",
  },
  {
    id: "LT0020",
    artist: "Teho. Ceas & Aalson",
    label: "Labo T.",
    titre: "Hysteria",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0020.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/1y254oYk4koQH6MUy5ybBF?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho-Ceas-Aalson_Hysteria",
  },
  {
    id: "LTC004",
    artist: "Various Artists",
    label: "Labo T.",
    titre: "Melodic Gems vol1",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTC004.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/2rDSyac3fmQVHNQhoVP994?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/MelodicGemsVol1",
  },
  {
    id: "LT0019",
    artist: "Teho",
    label: "Labo T.",
    titre: "Eagles",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0019.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/687LI57BvGEzBP2lvCmk0D?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Eagles",
  },
  {
    id: "LT0016",
    artist: "Teho",
    label: "Labo T.",
    titre: "Because",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0016.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/06aXfnJTAWaUBCoI68HAsX?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Because",
  },
  {
    id: "LTC003",
    artist: "Various Artists",
    label: "Labo T.",
    titre: "Waves in the box",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTC003.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/2MjhV6tqxqaUkKWQhBBf11?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/WavesInTheBox",
  },
  {
    id: "LT0014",
    artist: "Teho",
    label: "Labo T.",
    titre: "Live It",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0014.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/74EgL5CnIA1SeHkthfeQZ9?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_LiveIt",
  },
  {
    id: "EXT0017",
    artist: "Teho",
    label: "Inside records",
    titre: "Is That Worth It",
    cover: `${
      process.env.PUBLIC_URL + "/img/musicTeho/Teho_Is_That_Worth_It.jpg"
    }`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/track/4d5sgswHcIwXZ5APt1ZsSL?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://ffm.to/b5b23jl",
  },
  {
    id: "EXT0016",
    artist: "Teho",
    label: "Traum Schallplatten",
    titre: "Woody EP",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_Woody.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/track/4VqkAyqYKPQAvUoJhYnXuh?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Woody",
  },
  {
    id: "LT0010",
    artist: "Teho",
    label: "Labo T.",
    titre: "Mandala",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0010.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/5gWS2BBKEVXj97FMtmZdj6?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Mandala",
  },
  {
    id: "EXT0015",
    artist: "Teho",
    label: "Sapiens",
    titre: "Into The Wild",
    cover: `${
      process.env.PUBLIC_URL + "/img/musicTeho/Teho_Into_The_Wild.jpg"
    }`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/track/00VpPJg7gluM0dO4u7K9fZ?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://labo-t.lnk.to/1yrMKWE",
  },
  {
    id: "EXT0014",
    artist: "Teho",
    label: "Cinematique",
    titre: "Stuck In Reverse",
    cover: `${
      process.env.PUBLIC_URL + "/img/musicTeho/Teho_Stuck_In_Reverse.jpg"
    }`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/track/4sQfr9zt8CBC8fRfilXW3D?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "EXT0013",
    artist: "Teho",
    label: "Traum Schallplatten",
    titre: "Jupiter EP",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_Jupiter.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3CR7QR5pHiXoAAtuJi12g8?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "EXT0012",
    artist: "Teho",
    label: "Inner Symphony",
    titre: "Dreamcatcher",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_Dreamcatcher.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/track/4oBXr0Bi5S1OhtH0RxhXSM?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "LT0002R",
    artist: "Teho",
    label: "Labo T.",
    titre: "Feuilles d'Automne remixes",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0002R.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3AQ6lDkBnleI7oBPNwugJG?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_FeuillesDAutomneRemixes",
  },
  {
    id: "LT0002",
    artist: "Teho",
    label: "Labo T.",
    titre: "Feuilles d'Automne EP",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0002.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/1m0nM8dLjLO7f2EFL9nZjP?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_FeuillesDAutomne",
  },
  {
    id: "LT0001",
    artist: "Teho",
    label: "Labo T.",
    titre: "Together",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0001.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/1dUxTeHpbBT2N1e6JcfEGt?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Together",
  },
  {
    id: "EXT0011",
    artist: "Teho",
    label: "Chapter 24",
    titre: "Seven Stories 3",
    cover: `${
      process.env.PUBLIC_URL + "/img/musicTeho/Teho_Seven_Stories.jpg"
    }`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/track/4eKeb09oAoU8PrhDqfXYBX?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "EXT0010",
    artist: "Teho",
    label: "Parquet recordings",
    titre: "Issue 1",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_Issue_1.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/track/57pRyMVO7pPUefYkQF9hkK?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "EXT0009",
    artist: "Teho",
    label: "Parquet recordings",
    titre: "ADE / Arif EP",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_ADE.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/track/6TLFfgmkpASmhAHgF5BIqa?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "EXT0008",
    artist: "Teho & In-DikA",
    label: "Cinematique",
    titre: "Mathematics EP",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_MAthematics.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/2TlgLApm7cUo8X5OcrGPKF?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "EXT0007",
    artist: "Teho",
    label: "Manual music",
    titre: "Behind Me",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_Behind_Me.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/track/02sVtQ6XBFhPauNe1aLNCZ?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "EXT0006",
    artist: "Teho & Van Did",
    label: "Grrreat recordings",
    titre: "Long Way Huacachina",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_Long_Way.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/22Dk29y3bW393Qnc5RVFQJ?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "EXT0005",
    artist: "Teho",
    label: "Manual music",
    titre: "Uku EP",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_Uku.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/4TJz3pfeiaCVVWbZlr9w73?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "EXT0004",
    artist: "Teho",
    label: "Parquet recordings",
    titre: "Cliché / Union EP",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_Cliche.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/0wyjfi3VojhpD31tPChJpN?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "EXT0003",
    artist: "Teho & Tran",
    label: "Herzblut recordings",
    titre: "Koto EP",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_Koto.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3MYogWFTbX4tyrPOti1Ymc?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "EXT0002",
    artist: "Teho & Van Did",
    label: "Herzblut recordings",
    titre: "Hope EP",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/Teho_Hope.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/7JJSUyQuvpwn310XrvKC8m?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
  {
    id: "EXT0001",
    artist: "...",
    label: "on Spotify",
    titre: "Teho",
    cover: `${process.env.PUBLIC_URL + "/img/musicTeho/And_More.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/playlist/6Flh5iUmF7jYYWqSz23Cdb?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/I_Am_Teho",
  },
];
